<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <logo />
      </b-link>

      <b-col
        class="d-none d-lg-flex align-items-center p-5"
        lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>

      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        lg="4"
      >
        <b-col
          class="px-lx-2 mx-auto"
          sm="8"
          md="6"
          lg="12"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb"
          >
            Welcome to GTA CMS! 👋
          </b-card-title>

          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="userName"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    placeholder="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'authentication-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >Sign in</b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BImg, BInputGroup, BInputGroupAppend, BLink, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import router from '@/router';
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import useAuthentication from '@/modules/authentication/composables/useAuthentication';
import Logo from '@/layouts/components/Logo.vue';

export default {
  setup() {
    const { skin } = useAppConfig();
    const { login } = useAuthentication();
    const toast = useToast();

    const loginValidation = ref('');
    const password = ref('');
    const passwordFieldType = ref('password');
    const userName = ref('');

    return {
      // Reactive
      loginValidation,
      password,
      passwordFieldType,
      userName,

      // Computed
      imgUrl: computed(() => (skin.value === 'dark'
        ? require('@/assets/images/pages/login-v2-dark.svg')
        : require('@/assets/images/pages/login-v2.svg'))),
      passwordToggleIcon: computed(() => (passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon')),

      // Method
      togglePasswordVisibility: () => {
        passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
      },
      validationForm: () => {
        loginValidation.value.validate().then(async (validation) => {
          if (validation) {
            const { success, message } = await login(userName.value, password.value);

            if (success) {
              router.push({ name: 'dashboard' });
            } else {
              toast.error({
                component: ToastificationContentVue,
                props: { title: message, icon: 'XCircleIcon' }
              });
            }
          }
        });
      },

      // Form Validation
      required,
    };
  },
  components: {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BRow,
    ValidationObserver,
    ValidationProvider,
    Logo,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
