import store from '@/store';

const useAuthentication = () => {
  const login = async (username, password) => {
    try {
      const response = await store.dispatch('authentication/signIn', {
        username,
        password,
      });

      return { success: true, ...response };
    } catch (error) {
      console.error(error.response);
      const { data } = error.response;

      return data.status === 500
        ? { success: false, message: data.detail }
        : { success: false, message: data };
    }
  };

  const forgotPassword = async (username) => {
    try {
      await store.dispatch('authentication/forgotPassword', {
        username,
      });

      return { success: true, message: 'The email was sent successfully' };
    } catch (error) {
      console.error(error.response);

      return { success: false, message: 'An error occurred' };
    }
  };

  const resetPassword = async (username, token, password, confirmPassword) => {
    try {
      await store.dispatch('authentication/resetPassword', {
        username,
        token,
        password,
        confirmPassword,
      });

      return { success: true, message: 'Password was changed successfully.' };
    } catch (error) {
      console.error(error.response);

      return { success: false, message: 'An error occurred' };
    }
  };

  return {
    login,
    forgotPassword,
    resetPassword,
  };
};

export default useAuthentication;
